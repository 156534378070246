import { DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable, PLATFORM } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { LoopService } from 'services/loop-service';
import { NetService } from 'services/net-service';
import { RopeService } from 'services/rope-service';
import { ToastService } from 'services/toast-service';
import { ConnectionService } from 'services';
import { Connection } from 'models/Connections';
import { I18N } from 'aurelia-i18n';
import { RopeDirection } from 'models/RopeDirection';

@autoinject
export class FormRope {
  @bindable private netId: number;
  private net: Models.Net;
  private ropes: Array<Models.Rope>;
  private loops: Array<Models.Loop>;
  connections: Array<Connection>;
  private ropeNewFormVisible: boolean = false;
  private ropeEditFormVisible: number = null;
  private loopNewFormVisible: boolean = false;
  private loopEditFormVisible: number = null;
  private minimumBreakingStrength: any;
  private nextTabIndex: number = null;
  private locked: boolean = true;
  private validationMessages: Array<string>;
  protected netStandard: Models.NetStandard;
  private connectionsTitles: { [key: number]: string } = {};

  private subscription1: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;
  private subscription4: Subscription;

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private loopService: LoopService,
    private netService: NetService,
    private ropeService: RopeService,
    private connectionService: ConnectionService,
    private router: Router,
    private toastService: ToastService,
    private t: I18N
  ) {}

  activate(params: { NetId: number; Id: number }) {
    this.netId = params.NetId || params.Id;

    // Update ropes array when creating new ropes
    this.subscription1 = this.eventAggregator.subscribe('ropeListReset', () => {
      this.ropeEditFormVisible = null;
      this.getRopes(this.net.Id);
      this.eventAggregator.publish('offer-refresh', 'price');
    });

    // Update loops array when creating new loops
    this.subscription2 = this.eventAggregator.subscribe('loopListReset', () => {
      this.loopEditFormVisible = null;
      void this.getLoops(this.net.Id);
      this.eventAggregator.publish('offer-refresh', 'price');
    });

    this.subscription3 = this.eventAggregator.subscribe('ropeFormEditClose', () => {
      return this.getRopes(this.net.Id);
    });

    this.eventAggregator.subscribe('ropeFormNewClose', () => {
      this.ropeNewFormVisible = false;
    });

    this.subscription4 = this.eventAggregator.subscribe('loopFormEditClose', () => {
      return this.getLoops(this.net.Id);
    });

    this.eventAggregator.subscribe('loopFormNewClose', () => {
      this.loopNewFormVisible = false;
    });

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });

    void this.setup();
  }

  async setup() {
    await this.getNet(this.netId);
    await Promise.allSettled([this.getRopes(this.netId), this.getLoops(this.netId)]);
  }

  deactivate() {
    if (this.subscription1) {
      this.subscription1.dispose();
    }
    if (this.subscription2) {
      this.subscription2.dispose();
    }
    if (this.subscription3) {
      this.subscription3.dispose();
    }
    if (this.subscription4) {
      this.subscription4.dispose();
    }
  }

  canDeactivate() {
    if (
      this.ropeNewFormVisible ||
      this.ropeEditFormVisible > 0 ||
      this.loopNewFormVisible ||
      this.loopEditFormVisible > 0
    ) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.subFormOpenHeading',
            message: 'dialog.subFormOpenMessage',
            actions: {
              delete: { enabled: false },
              save: { enabled: false },
              cancel: { enabled: true, t: 'dialog.cancel' },
              dontsave: { enabled: false },
              continue: { enabled: true, t: 'dialog.continue' },
            },
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'continue') {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  // Get net and netdimension and calculate min breaking strength
  private async getNet(id: number) {
    try {
      const net = await this.netService.get(id + '?$expand=NetDimension($expand=NetStandard)');
      if (net.NetDimension.DimensionClassId) {
        this.netStandard = net?.NetDimension?.NetStandard;
        try {
          const calculation = await this.ropeService.calculateMinBreakingStrength(net.NetDimension.DimensionClassId);
          this.minimumBreakingStrength = calculation;
        } catch (error) {
          this.errorService.handleError(error);
        }
      }
      this.net = net;
      this.locked = net.Locked || this.netIsLinkedToAnalysis(net.NetDimension) ? true : false;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getRopes(netId: number) {
    try {
      this.ropes = await this.ropeService.getAll(
        '?$expand=RopePlacement,RopeDimension,RopeHardness,RopeType&$filter=NetId eq ' + netId + '&$orderby=Sort'
      );

      const messages = await this.ropeService.validateRopes(netId);
      const verticalRopes = this.ropes?.filter((rope) => {
        return rope.RopePlacement.Direction === RopeDirection.Vertical;
      });

      this.validationMessages = messages.map((message: string) =>
        this.t.tr(message, {
          x: this.net?.NetDimension.SideRopes,
          y: verticalRopes?.reduce((acc, r) => acc + (r.Amount || 0), 0),
        })
      );
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getLoops(netId: number) {
    try {
      this.loops = await this.loopService.getAll(
        '?$expand=LoopPositionHorizontal,LoopType,LoopPositionVertical,Connections&$filter=NetId eq ' + netId
      );

      await this.getConnections(netId);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getConnections(netId: number) {
    try {
      this.connections = await this.connectionService.getAll('?$expand=Coupling&$filter=Netid eq ' + netId);
      //Have to this because we cant expand Coupling in Connections in the getLoops call
      this.loops.forEach((loop) => {
        this.connectionsTitles[loop.Id] = this.connections.find((c) => c.LoopId === loop.Id)?.Coupling?.Title;
      });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async deleteRope(id: number) {
    try {
      await this.ropeService.delete(id);
      this.toastService.showSuccess('rope.deleted');
      this.getRopes(this.net.Id);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async duplicateRope(id: number) {
    try {
      const rope = await this.ropeService.get(id);
      const newRope = new Models.Rope();

      newRope.ActualBreakingStrength = rope.ActualBreakingStrength;
      newRope.Amount = rope.Amount;
      newRope.ExtraRopeNumber = rope.ExtraRopeNumber;
      newRope.MeterFromWaterLine = rope.MeterFromWaterLine;
      newRope.MinBreakingStrength = rope.MinBreakingStrength;
      newRope.NetId = this.net.Id;
      newRope.RopeDimensionId = rope.RopeDimensionId;
      newRope.RopeHardnessId = rope.RopeHardnessId;
      newRope.RopePlacementId = rope.RopePlacementId;
      newRope.RopePlacementKind = rope.RopePlacementKind;
      newRope.RopeTypeId = rope.RopeTypeId;
      newRope.Sort = rope.Sort;

      this.toastService.showSuccess('rope.duplicated');

      await this.ropeService.post(newRope);
      await this.getRopes(this.net.Id);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async deleteLoop(id: number) {
    try {
      await this.loopService.delete(id);
      this.toastService.showSuccess('loop.deleted');
      void this.getLoops(this.net.Id);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected netIsLinkedToAnalysis(netDimension: Models.NetDimension) {
    if (!netDimension) return false;
    return (
      !!netDimension.AnalysisVariantId ||
      !!netDimension.AnalysisVariant2Id ||
      !!netDimension.AnalysisVariant3Id ||
      !!netDimension.AnalysisVariant4Id ||
      !!netDimension.AnalysisVariant_2Id ||
      !!netDimension.AnalysisVariant2_2Id ||
      !!netDimension.AnalysisVariant3_2Id ||
      !!netDimension.AnalysisVariant4_2Id
    );
  }
}
