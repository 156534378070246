import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { CuttingStrapTypeService } from 'services/cutting-strap-type-service';
import { ErrorService } from 'services/error-service';
import { NetPreparationService } from 'services/net-preparation-service';
import { NetService } from 'services/net-service';
import { PackagingMethodService } from 'services/packaging-method-service';
import { PackagingTypeService } from 'services/packaging-type-service';
import { PreparationRopeService } from 'services/preparation-rope-service';
import { StrapTypeService } from 'services/strap-type-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';

const logger: Logger = LogManager.getLogger("form-preparation");

@autoinject
export class FormPreparation {
  private netPreparationId: number;
  private netId: number;
  private originalObject;
  private net: Models.Net;
  private locked = true;

  private netpreparation: Models.NetPreparation;
  private preparationropes: Array<Models.PreparationRope>;
  private nextTabIndex: number = null;
  private packagingmethods: Array<Models.PackagingMethod>;
  private preparationRopeNewFormVisible: boolean = false;
  private preparationRopeEditFormVisible: number = null;

  public noPreparationDataExists: boolean = false;

  private subscription1: Subscription;  
  private subscription2: Subscription;
  private subscription3: Subscription;

  constructor(
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private netPreparationService: NetPreparationService,
    private preparationRopeService: PreparationRopeService,
    private dialogService: DialogService,
    private netService: NetService,
    private strapTypeService: StrapTypeService,
    private cuttingStrapTypeService: CuttingStrapTypeService,
    private packagingTypeService: PackagingTypeService,
    private packagingMethodService: PackagingMethodService,
    private utility: Utility
  ) {
  }
  private activate(params) {
    this.netId = params.NetId || params.Id;
    this.getNet(this.netId);
    this.getPackagingMethods();

    // Update NetPreparation when creating new preparationRopes
    this.subscription1 = this.eventAggregator.subscribe('preparationRopeListReset', (value) => {
      this.preparationRopeEditFormVisible = null;
      this.getPreparationRopes();
      this.eventAggregator.publish('offer-refresh', 'price');
    });

    this.subscription2 = this.eventAggregator.subscribe('preparationRopeFormEditClose', (value) => {
      this.getPreparationRopes();
    });

    this.eventAggregator.subscribe('preparationRopeFormNewClose', (value) => {
      this.preparationRopeNewFormVisible = false;
    });

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });

    // refresh if locked / unlocked
    this.subscription3 = this.eventAggregator.subscribe('netLocked', (value) => {
      this.getNet(this.netId);
    });

  }

  private deactivate() {
    if (this.subscription1) {
      this.subscription1.dispose();
    }
    if (this.subscription2) {
      this.subscription2.dispose();
    }
    if (this.subscription3) {
      this.subscription3.dispose();
    }
  }

  private deletePreparationRope(id) {
    this.preparationRopeService
      .delete(id)
      .then((res) => {
        this.getPreparationRopes();
        this.toastService.showSuccess('preparationrope.deleted');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateNetPreparation() {
    this.netpreparation.PreparationRopes = null;
    this.netPreparationService
      .put(this.netpreparation, this.netpreparation.Id)
      .then((res) => {
        this.toastService.showSuccess('netpreparation.updated');
        this.getNetPreparation(this.netPreparationId);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private cancel() {
    this.netpreparation = this.originalObject;
  }

  private getNet(id) {
    this.netService
      .get(id)
      .then((res) => {        
        this.net = res;
        this.netPreparationId = res.NetPreparationId;
        this.locked = res.Locked ? res.Locked : false;
        logger.debug('res', res);
        if (this.net.NetPreparationId) {
          this.noPreparationDataExists = false;
          this.getNetPreparation(this.netPreparationId);          
        } else {
          this.noPreparationDataExists = true;
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getNetPreparation(id) {
    this.netPreparationService
      .get(id)
      .then((res) => {
        this.netpreparation = res;
        this.updateOriginalObject();
        this.getPreparationRopes();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getPackagingMethods() {
    this.packagingMethodService
      .getAllCached()
      .then((res) => {        
        this.packagingmethods = res.filter(x => !x.IsDeleted);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getPreparationRopes() {
    this.preparationRopeService
      .getAll('?$filter=NetPreparationId eq ' + this.netpreparation.Id + '&$expand=RopeType,PreparationRopeType,RopeDimension,PreparationRopePlacement,PreparationRopeKnot1,PreparationRopeKnot2,PreparationRopeKnotPlacement1,PreparationRopeKnotPlacement2')
      .then((res) => {
        this.preparationropes = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private canDeactivate() {
    if (!this.net.Locked && ((this.originalObject && !this.utility.areEqual(this.netpreparation, this.originalObject)) || (this.preparationRopeNewFormVisible || this.preparationRopeEditFormVisible > 0))) {
      if (this.originalObject && !this.utility.areEqual(this.netpreparation, this.originalObject)) {
        // tslint:disable-next-line:no-console
        logger.debug(this.originalObject, this.netpreparation);
        return this.dialogService.open({
          viewModel: Prompt,
          model: { message: 'dialog.unsavedChangesText' }
        }).whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'save') {
              // save the nettingtype and let that function handle the rest of the logic
              this.updateNetPreparation();
              return false;
            } else {
              return true;
            }
          }
        });
      }
      if (this.preparationRopeNewFormVisible || this.preparationRopeEditFormVisible > 0) {
        return this.dialogService.open({
          viewModel: Prompt,
          model: {
            header: 'dialog.subFormOpenHeading',
            message: 'dialog.subFormOpenMessage',
            actions: {
              delete: { enabled: false },
              save: { enabled: false },
              cancel: { enabled: true, t: 'dialog.cancel'  },
              dontsave: { enabled: false },
              continue: { enabled: true, t: 'dialog.continue'  }
            }
          }
        }).whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'continue') {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
      }
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  private updateOriginalObject() {
    if (!this.netpreparation) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(this.netpreparation));
  }
}
